<template>
    <div class="m-3" v-if="$store.state.login.user.userData.roles.includes('admin_func')">
        <domaine-accordion-vue class="mb-3"></domaine-accordion-vue>
        <action-accordion-vue class="mb-3"></action-accordion-vue>
        <family-accordion-vue class="mb-3"></family-accordion-vue>
        <type-accordion-vue class="mb-3"></type-accordion-vue>
    </div>
</template>

<script>
import ActionAccordionVue from '../components/events/ActionAccordion.vue'
import DomaineAccordionVue from '../components/events/DomaineAccordion.vue'
import FamilyAccordionVue from '../components/events/FamilyAccordion.vue'
import TypeAccordionVue from '../components/events/TypeAccordion.vue'

export default {
    components: {
        DomaineAccordionVue,
        FamilyAccordionVue,
        ActionAccordionVue,
        TypeAccordionVue,
    },
}
</script>

<style></style>
