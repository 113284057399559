<template>
    <div>
        <simple-accordion>
            <template #accordionTitle>
                <span class="me-3">Type d'évenement</span>
                <button class="btn btn-primary btn-sm" @click="onPLusClicked">
                    <font-awesome-icon icon="fa-solid fa-plus" />
                </button>
            </template>
            <template #accordionContent>
                <div class="listTypes">
                    <vue-good-table
                        ref="tableTypes"
                        @on-selected-rows-change="onSelected"
                        :selected-rows="[]"
                        :columns="columns"
                        :rows="rows"
                        :select-options="{
                            enabled: false,
                            selectionText: 'Types sélectionnées',
                            clearSelectionText: 'Effacer la sélection',
                        }"
                        max-height="600px"
                        :fixed-header="true"
                    >
                        <template slot="loadingContent">
                            <span class="vgt-loading__content">Téléchargement...</span>
                        </template>
                        <template #table-row="props">
                            <div v-if="props.column.field === 'actions'" class="uk-text-center">
                                <i class="fa-solid fa-pen-to-square" @click="onUpdate(props.row)"></i>
                                <i class="fa-solid fa-trash" @click="onDelete(props.row)"></i>
                            </div>
                            <div
                                v-else-if="props.column.field === 'available_for_worker_profile'"
                                class="uk-text-center"
                            >
                                {{ props.row.available_for_worker_profile ? 'OUI' : 'NON' }}
                            </div>
                            <div
                                v-else-if="props.column.field === 'available_for_agency_profile'"
                                class="uk-text-center"
                            >
                                {{ props.row.available_for_agency_profile ? 'OUI' : 'NON' }}
                            </div>
                            <div
                                v-else-if="props.column.field === 'should_notify_worker_profile'"
                                class="uk-text-center"
                            >
                                {{ props.row.should_notify_worker_profile ? 'OUI' : 'NON' }}
                            </div>
                            <div
                                v-else-if="props.column.field === 'should_notify_agency_profile'"
                                class="uk-text-center"
                            >
                                {{ props.row.should_notify_agency_profile ? 'OUI' : 'NON' }}
                            </div>
                            <div v-else-if="props.column.field === 'status_value_on_terminate'" class="uk-text-center">
                                {{
                                    props.row.status_value_on_terminate
                                        ? props.row.status_value_on_terminate.value
                                        : 'Inchangé'
                                }}
                            </div>
                            <div v-else-if="props.column.field === 'dispo_status'">
                                <div>
                                    <div style="margin-bottom: 10px">
                                        <select class="selAddStatus" v-model="rows[props.index].dispo">
                                            <option v-for="(it, idx) in statusList" :key="idx" :value="it">
                                                {{ it.value }}
                                            </option>
                                        </select>
                                        <font-awesome-icon
                                            v-if="!loading"
                                            class="addStatus"
                                            icon="fa-solid fa-plus"
                                            @click="onAddStatus(props.index)"
                                        />
                                        <div v-if="loading" class="uk-inline" uk-spinner="ratio:0.5"></div>
                                    </div>
                                    <div v-if="props.row.status">
                                        <div class="boxStatus" v-for="(it, idx) in props.row.status" :key="idx">
                                            <div class="status">{{ it.value }}</div>
                                            <div class="delete">
                                                <i
                                                    class="delete-icon fa-sharp fa-solid fa-xmark"
                                                    @click="onDeleteStatus(props.index, idx)"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>Aucun statut disponibles</div>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </template>
        </simple-accordion>
        <blank-modal ref="modalNewType" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">{{ titleDlg }}</legend>
            </template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <div class="uk-margin">
                        <label for="codeFamille">Code</label>
                        <input id="codeFamille" class="uk-input" type="text" v-model="form.code" required />
                    </div>
                    <div class="uk-margin">
                        <label for="labelType">Libellé</label>
                        <input id="labelType" class="uk-input" type="text" v-model="form.label" required />
                    </div>
                    <div class="uk-margin">
                        <label for="bodyType">Corps</label>
                        <input id="bodyType" class="uk-input" type="text" v-model="form.body" />
                    </div>
                    <div class="uk-margin" v-if="families[0]">
                        <label for="family">Famille</label>
                        <select id="family" class="uk-select" v-model="family">
                            <option v-for="(it, idx) in families" :key="idx" :value="it">{{ it.wording }}</option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label for="status">Statut quand l'evenement est crée</label>
                        <select id="status" class="uk-select" v-model="status_value_on_terminate">
                            <option selected="selected" :value="{}">Inchangé</option>
                            <optgroup label="Statut disponibles" v-if="statusList[0]">
                                <option v-for="(it, idx) in statusList" :key="idx" :value="it">{{ it.value }}</option>
                            </optgroup>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label for="hasPhoto">Photo</label>
                        <select id="hasPhoto" class="uk-select" v-model="has_photo">
                            <option v-for="(it, idx) in DISPO_LABEL" :key="idx" :value="it">{{ it.label }}</option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label for="hasComment">Commentaire</label>
                        <select id="hasComment" class="uk-select" v-model="has_comment">
                            <option v-for="(it, idx) in DISPO_LABEL" :key="idx" :value="it">{{ it.label }}</option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label for="availableAgency" class="c-mr">Disponible pour le compte exploitant suez</label>
                        <input
                            id="availableAgency"
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="available_for_agency"
                        />
                    </div>
                    <div class="uk-margin">
                        <label for="availableAgency" class="c-mr">Disponible pour le compte sous traitant</label>
                        <input
                            id="availableAgency"
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="available_for_worker"
                        />
                    </div>
                    <div class="uk-margin">
                        <label for="availableAgency" class="c-mr">Notifier sous traitant</label>
                        <input
                            id="availableAgency"
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="should_notify_worker"
                        />
                    </div>
                    <div class="uk-margin">
                        <label for="availableAgency" class="c-mr">Notifier exploitant suez</label>
                        <input
                            id="availableAgency"
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="should_notify_agency"
                        />
                    </div>
                </fieldset>
            </template>
            <template #modalFooterExtra>
                <button @click="onSave" class="btn btn-primary">
                    Enregistrer
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
        <blank-modal ref="modalDelete" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Suppression</legend>
            </template>
            <template #modalBody> Voulez-vous supprimer ce type d'évenement? </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="onDeleteOk">
                    Oui
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
    data() {
        return {
            loading: false,
            form: {
                code: '',
                label: '',
                body: '',
            },
            columns: [
                {
                    label: 'Domaine',
                    field: 'event_family.event_action.event_domain.wording',
                },
                {
                    label: 'Action',
                    field: 'event_family.event_action.wording',
                },
                {
                    label: 'Famille',
                    field: 'event_family.wording',
                },
                {
                    label: 'Code Pt Temp',
                    field: 'code',
                },
                {
                    label: 'Libellé Pt Temp',
                    field: 'wording',
                },
                {
                    label: 'Exploitant Suez',
                    field: 'available_for_agency_profile',
                },
                {
                    label: 'Profil ST',
                    field: 'available_for_worker_profile',
                },
                {
                    label: 'Photo',
                    field: 'has_photo',
                },
                {
                    label: 'Commentaire',
                    field: 'has_comment',
                },
                {
                    label: 'Notif sous traitant',
                    field: 'should_notify_worker_profile',
                },
                {
                    label: 'Notif exploitant suez',
                    field: 'should_notify_agency_profile',
                },
                // {
                //   label: 'Entete notif',
                //   field: 'notif',
                // },
                {
                    label: 'Libellé notif',
                    field: 'body',
                    // hidden: this.shouldBeHidden('big')
                },
                {
                    label: 'Statut après création',
                    field: 'status_value_on_terminate',
                },
                {
                    label: 'Statut dispo',
                    field: 'dispo_status',
                    // hidden: this.shouldBeHidden('big')
                },
                {
                    label: '',
                    field: 'actions',
                },
            ],
            DISPO_LABEL: [
                { value: 'ON', label: 'Obligatoire' },
                { value: 'OFF', label: 'Non visible' },
                { value: 'OPTIONAL', label: 'Optionnel' },
            ],
            selected: [],
            family: undefined,
            status_value_on_terminate: {},
            available_for_agency: true,
            available_for_worker: true,
            has_photo: { value: 'OPTIONAL', label: 'Optionnel' },
            has_comment: { value: 'OPTIONAL', label: 'Optionnel' },
            should_notify_agency: false,
            should_notify_worker: false,
            available_status: [],
            is_signature_required: false,
            dispo: undefined,
            isUpdate: false,
            rows: [],
        }
    },
    methods: {
        clearInput() {
            this.form = {
                code: '',
                label: '',
                body: '',
            }
            this.family = this.families[0] ? this.families[0] : undefined
            this.available_for_agency = true
            this.available_for_worker = true
            this.has_photo = this.DISPO_LABEL[0]
            this.has_comment = this.DISPO_LABEL[0]
            this.should_notify_agency = true
            this.should_notify_worker = true
            this.status_value_on_terminate = {}
            this.is_signature_required = false
        },
        onPLusClicked() {
            this.clearInput()
            this.isUpdate = false
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewType.$el).show()
        },
        onSave() {
            if (!this.families[0]) {
                this.$store.dispatch('notifications/showWarning', 'Il faut créer une famille d`abord!')
                return
            }
            if (!this.form.code || !this.form.label) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez remplir tous les champs')
                return
            }
            if (!this.has_photo) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez selectionner la valeur du photo')
                return
            }
            if (!this.has_comment) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez selectionner la valeur du commentaire')
                return
            }
            const data = {
                code: this.form.code,
                wording: this.form.label,
                event_family: this.family.id,
                status_value_on_terminate: this.status_value_on_terminate.id ? this.status_value_on_terminate.id : null,
                body: this.form.body ? this.form.body : '',
                available_for_agency_profile: this.available_for_agency,
                available_for_worker_profile: this.available_for_worker,
                has_photo: this.has_photo.value,
                has_comment: this.has_comment.value,
                should_notify_agency_profile: this.should_notify_agency,
                should_notify_worker_profile: this.should_notify_worker,
                is_signature_required: this.is_signature_required,
            }
            if (!this.isUpdate) {
                this.add(data)
                return
            }
            this.loading = true
            this.$http
                .updateType(this.selected[0].id, data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewType.$el).hide()
                    this.$store.dispatch('adminEvent/setTypes', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        add(data) {
            this.loading = true
            this.$http
                .addType(data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewType.$el).hide()
                    this.$store.dispatch('adminEvent/setTypes', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSelected(val) {
            this.selected = val.selectedRows
        },
        onDelete(props) {
            this.selected[0] = props
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).show()
        },
        onDeleteOk() {
            this.loading = true
            this.$http
                .deleteType(this.selected[0].id)
                .then(() => {
                    this.getAllTypes()
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).hide()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onUpdate(props) {
            this.selected[0] = props
            this.isUpdate = true
            this.fillInput(props)
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewType.$el).show()
        },
        async getAllTypes() {
            const rows = await this.$http.getAllTypes()
            this.$store.dispatch('adminEvent/setTypes', rows)
        },
        onAddStatus(idx) {
            if (!this.rows[idx].dispo) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez selectionner un statut')
                return
            }
            if (this.rows[idx].status.find((s) => this.rows[idx].dispo.id === s.id)) {
                this.$store.dispatch('notifications/showWarning', 'Statut déjà ajouté!')
                return
            }
            this.loading = true
            this.$http
                .addTypeStatus(this.rows[idx].id, this.rows[idx].dispo.id)
                .then((res) => {
                    this.$store.dispatch('notifications/showInfo', 'Statut ajouté avec succeés!')
                    this.$store.dispatch('adminEvent/setTypes', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onDeleteStatus(idx, statusIdx) {
            this.loading = true
            this.$http
                .deleteTypeStatus(this.rows[idx].id, this.rows[idx].status[statusIdx].id)
                .then((res) => {
                    this.$store.dispatch('notifications/showInfo', 'Statut supprimé avec succeés!')
                    this.$store.dispatch('adminEvent/setTypes', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getLabelOption(value) {
            return this.DISPO_LABEL.find((l) => l.value === value)
        },
        fillInput(data) {
            this.form = {
                code: data.code,
                label: data.wording,
                body: data.body,
            }
            this.family = data.event_family
            this.available_for_agency = data.available_for_agency_profile
            this.available_for_worker = data.available_for_worker_profile
            this.has_photo = this.getLabelOption(data.has_photo)
            this.has_comment = this.getLabelOption(data.has_comment)
            this.should_notify_agency = data.should_notify_agency_profile
            this.should_notify_worker = data.should_notify_worker_profile
            this.is_signature_required = data.is_signature_required
            if (data.status_value_on_terminate) this.status_value_on_terminate = data.status_value_on_terminate
            else this.status_value_on_terminate = {}
        },
        shouldBeHidden: function (at) {
            const bp = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'big', 'giga']
            if (!bp.includes(at)) {
                return false
            }

            return !(bp.indexOf(at) <= bp.indexOf(this.$mq))
        },
    },
    computed: {
        ...mapState('status', { statusList: 'list' }),
        ...mapState('adminEvent', { typesList: 'types' }),
        ...mapGetters({
            families: 'adminEvent/getFamilies',
        }),
        titleDlg() {
            if (this.isUpdate) return 'Modification type'
            return 'Nouveau type'
        },
    },
    watch: {
        families(val) {
            if (!this.family && val[0]) this.family = this.families[0]
        },
        statusList(val) {
            if (!this.dispo && val[0]) {
                this.dispo = val[0]
            }
        },
        typesList(val) {
            if (val) {
                this.rows = val.map((v) => {
                    v.dispo = undefined
                    return v
                })
            }
        },
    },
    mounted() {
        this.getAllTypes()
        this.$store.dispatch('status/updateStatusList')
    },
}
</script>

<style scoped>
label {
    margin-bottom: 10px;
}

.status {
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px grey solid;
    padding: 2px;
    border-radius: 2px;
}

.addStatus {
    cursor: pointer;
    margin-right: 10px;
}

.selAddStatus {
    padding: 5px;
    margin-right: 10px;
    width: 120px;
}

i {
    margin: 10px;
    cursor: pointer;
}

.boxStatus {
    position: relative;
}

.delete {
    position: absolute;
    top: -7px;
    right: 2px;
    cursor: pointer;
    background-color: grey;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-icon {
    color: white;
}
</style>
