<template>
    <div>
        <simple-accordion>
            <template #accordionTitle>
                <span class="me-3">Famille</span>
                <button class="btn btn-primary btn-sm" @click="onPLusClicked">
                    <font-awesome-icon icon="fa-solid fa-plus" />
                </button>
            </template>
            <template #accordionContent>
                <div class="listFamille">
                    <vue-good-table
                        ref="tableFamille"
                        @on-selected-rows-change="onSelected"
                        :selected-rows="[]"
                        :columns="columns"
                        :rows="$store.getters['adminEvent/getFamilies']"
                        :select-options="{
                            enabled: false,
                            selectionText: 'Familles sélectionnées',
                            clearSelectionText: 'Effacer la sélection',
                        }"
                        max-height="200px"
                        :fixed-header="true"
                    >
                        <template slot="loadingContent">
                            <span class="vgt-loading__content">Téléchargement...</span>
                        </template>
                        <div slot="selected-row-actions">
                            <button @click="onDelete" v-if="selected.length == 1">Supprimer</button>
                        </div>
                        <template #table-row="props">
                            <div v-if="props.column.field === 'actions'" class="uk-text-center">
                                <i class="fa-solid fa-pen-to-square" @click="onUpdate(props.row)"></i>
                                <i class="fa-solid fa-trash" @click="onDelete(props.row)"></i>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </template>
        </simple-accordion>
        <blank-modal ref="modalNewFamille" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">{{ titleDlg }}</legend>
            </template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <div class="uk-margin" v-if="actions[0]">
                        <label for="action">Action</label>
                        <select id="action" class="uk-select" v-model="action">
                            <option v-for="(it, idx) in actions" :key="idx" :value="it">{{ it.wording }}</option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label for="codeFamille">Code</label>
                        <input id="codeFamille" class="uk-input" type="text" v-model="form.code" required />
                    </div>
                    <div class="uk-margin">
                        <label for="labelFamille">Libellé</label>
                        <input id="labelFamille" class="uk-input" type="text" v-model="form.label" required />
                    </div>
                </fieldset>
            </template>
            <template #modalFooterExtra>
                <button @click="onSave" class="btn btn-primary">
                    Enregistrer
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
        <blank-modal ref="modalDelete" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Suppression</legend>
            </template>
            <template #modalBody> Voulez-vous supprimer cete famille? </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="onDeleteOk">
                    Oui
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            loading: false,
            form: {
                code: '',
                label: '',
            },
            columns: [
                {
                    label: 'Code',
                    field: 'code',
                },
                {
                    label: 'Libellé',
                    field: 'wording',
                },
                {
                    label: 'Action',
                    field: 'event_action.wording',
                },
                {
                    label: '',
                    field: 'actions',
                },
            ],
            selected: [],
            action: undefined,
            isUpdate: false,
        }
    },
    methods: {
        clearInput() {
            this.form = {
                code: '',
                label: '',
            }
            if (!this.action && this.actions[0]) this.action = this.actions[0]
        },
        onPLusClicked() {
            this.isUpdate = false
            this.clearInput()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFamille.$el).show()
        },
        onSave() {
            if (!this.actions[0]) {
                this.$store.dispatch('notifications/showWarning', 'Il faut créer un action d`abord!')
                return
            }
            if (!this.form.code || !this.form.label) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez remplir tous les champs')
                return
            }
            const data = {
                code: this.form.code,
                wording: this.form.label,
                event_action_id: this.action.id,
            }
            if (!this.isUpdate) {
                this.add(data)
                return
            }
            this.loading = true
            this.$http
                .updateFamily(this.selected[0].id, data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFamille.$el).hide()
                    this.$store.dispatch('adminEvent/setFamilies', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        add(data) {
            this.loading = true
            this.$http
                .addFamily(data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFamille.$el).hide()
                    this.$store.dispatch('adminEvent/setFamilies', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSelected(val) {
            this.selected = val.selectedRows
        },
        onDelete(props) {
            this.selected[0] = props
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).show()
        },
        onUpdate(props) {
            this.selected[0] = props
            this.isUpdate = true
            this.fillInput(props)
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFamille.$el).show()
        },
        async getAllFamilles() {
            const rows = await this.$http.getAllFamilies()
            this.$store.dispatch('adminEvent/setFamilies', rows)
        },
        async getAllTypes() {
            const rows = await this.$http.getAllTypes()
            this.$store.dispatch('adminEvent/setTypes', rows)
        },
        updateAll() {
            this.getAllFamilles()
            this.getAllTypes()
        },
        onDeleteOk() {
            this.loading = true
            this.$http
                .deleteFamily(this.selected[0].id)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).hide()
                    this.updateAll()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fillInput(data) {
            this.form = {
                code: data.code,
                label: data.wording,
            }
            this.action = data.event_action
        },
    },
    computed: {
        ...mapGetters({
            actions: 'adminEvent/getActions',
        }),
        titleDlg() {
            if (this.isUpdate) return 'Modification famille'
            return 'Nouvelle famille'
        },
    },
    watch: {
        actions(val) {
            if (!this.action && val[0]) this.action = this.actions[0]
        },
    },
    mounted() {
        this.getAllFamilles()
    },
}
</script>

<style scoped>
label {
    margin-bottom: 10px;
}

i {
    margin: 10px;
    cursor: pointer;
}
</style>
